import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';

const borderCss = css`
  border: ${themeGet('borders.3')};
  color: ${themeGet('colors.primary')};
`;
export const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 90vw;
  iframe {
    ${borderCss}
  }
  .videoVimeo {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: 50px;
  }

  .videoVimeoMobile {
    width: 100%;
    display: none;
  }
  .overlay {
    position: absolute;
    background-color: transparent;
    z-index: 2;
  }

  .overlayMobile {
    position: absolute;
    background-color: transparent;
    z-index: 2;
    display: none;
  }

  @media screen and (max-width: 800px) {
    margin-top: 16px;
    .videoVimeo,
    .overlay {
      display: none;
    }
    .videoVimeoMobile,
    .overlayMobile {
      display: flex;
      justify-content: center;
    }
  }
  @media screen and (max-width: 350px) {
    //remove borders for iphone SE and small smartphones
    iframe {
      border-color: transparent;
    }
  }
`;
