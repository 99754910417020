import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import { SectionHeader } from '../appClassic.style';
import GuideSection from './guide.style';
import Fade from 'react-reveal/Fade';
import { injectIntl } from 'gatsby-plugin-intl';
import Video from '../../../components/Video/video';
import modeemploi from 'common/src/assets/docs/modedemploi.pdf';
import modeemploi_en from 'common/src/assets/docs/modedemploi_en.pdf';

const Guide = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        guide {
          image {
            publicURL
          }
        }
      }
    }
  `);
  const { image } = data.appClassicJson.guide;

  return (
    <GuideSection id='guide'>
      {intl.locale === 'fr' && (
        <Container>
          <SectionHeader>
            <Heading
              as='h5'
              content={intl.formatMessage({ id: 'guide.title' }).toUpperCase()}
            />
            <Heading content={intl.formatMessage({ id: 'guide.slogan' })} />
          </SectionHeader>
          <Fade up delay={100}>
            <Video width={700} locale={intl.locale} trailer={false} />
          </Fade>
          <Fade up delay={100}>
            <div className='fullRowCenter'>
              <DownloadTag />
            </div>
          </Fade>
        </Container>
      )}
    </GuideSection>
  );
};

export default injectIntl(Guide);

const DownloadTagWithIntl = ({ intl }) => (
  <a
    className='download_guide'
    href={intl.locale === 'fr' ? modeemploi : modeemploi_en}
    download='guide.pdf'
  >
    {intl.formatMessage({ id: 'guide.download' })}
  </a>
);
export const DownloadTag = injectIntl(DownloadTagWithIntl);
