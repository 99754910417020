import React, { forwardRef, useRef, Fragment } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { VideoWrapper } from './VideoWrapper';

const getVideoIdHelper = (locale, trailer) => {
  if (!trailer) {
    return '392175392';
  }
  switch (locale) {
    case 'fr':
      return '387304663';
    case 'de':
      return '387305318';
    case 'en':
      return '387305302';
    default:
      return '387305302';
  }
};
const Video = (props, passedRef) => {
  const {
    locale = 'en',
    width = 500,
    widthMobile = 350,
    controls = true,
    onClick,
    trailer = true
  } = props;
  const ratio = 0.57;
  const height = width * ratio;
  const heightMobile = widthMobile * ratio;
  const videoId = getVideoIdHelper(locale, trailer);

  const vimeoPlayer = useRef(null);

  const videoProps = {
    showTitle: false,
    showPortrait: false,
    video: videoId,
    autoplay: false,
    controls
  };

  return (
    <VideoWrapper colors='primary'>
      <Vimeo
        {...videoProps}
        className='videoVimeo'
        width={width}
        height={height}
        onReady={player => {
          if (passedRef) passedRef.current = player;
          vimeoPlayer.current = player;
        }}
      />
      <Vimeo
        {...videoProps}
        className='videoVimeoMobile'
        video={videoId}
        width={widthMobile}
        height={heightMobile}
      />
      {!controls && (
        <Fragment>
          <div
            onClick={onClick}
            style={{ width, height }}
            className='overlay'
          />
          <div
            onClick={onClick}
            style={{ width: widthMobile, height: heightMobile }}
            className='overlayMobile'
          />
        </Fragment>
      )}
    </VideoWrapper>
  );
};

export default forwardRef(Video);
