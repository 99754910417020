import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import { mediaRecordOutline } from 'react-icons-kit/typicons/mediaRecordOutline';
import { plus } from 'react-icons-kit/typicons/plus';
import { starOutline } from 'react-icons-kit/typicons/starOutline';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
import Tab, { Panel } from 'common/src/components/Tabs';
import Container from 'common/src/components/UI/Container';
import FeatureBlock from 'common/src/components/FeatureBlock';
import { SectionHeader } from '../appClassic.style';
import SectionWrapper, { TabArea } from './featureTab.style';
import { injectIntl } from 'gatsby-plugin-intl';

const FeatureTab = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        featuresTab {
          tab {
            id
            color
            icon {
              publicURL
            }
            image {
              publicURL
            }
          }
        }
      }
    }
  `);
  const { tab } = data.appClassicJson.featuresTab;

  return (
    <SectionWrapper>
      <Container>
        <SectionHeader>
          <Fade up>
            {/* <Heading as="h5" content={intl.formatMessage({id: 'features.slogan'})} /> */}
            <Heading content={intl.formatMessage({ id: 'features.title' })} />
          </Fade>
        </SectionHeader>
        <Fade up delay={100}>
          <TabArea>
            <Tab active={0}>
              {tab.map(item => (
                <Panel
                  title={
                    <FeatureBlock
                      style={{ '--color': `${item.color}` }}
                      iconPosition='left'
                      icon={
                        <Fragment>
                          <Icon className='plus' icon={plus} />
                          <Icon className='circle' icon={mediaRecordOutline} />
                          <Image src={item.icon.publicURL} alt={'image'} />
                          <Icon className='star' icon={starOutline} />
                        </Fragment>
                      }
                      title={
                        <Heading
                          as='h3'
                          content={intl.formatMessage({
                            id: `features.item.title_${item.id}`
                          })}
                        />
                      }
                      description={
                        <Text
                          content={intl.formatMessage({
                            id: `features.item.description_${item.id}`
                          })}
                        />
                      }
                    />
                  }
                  key={`app-tab--key${item.id}`}
                >
                  <Fade>
                    <Image
                      src={item.image.publicURL}
                      alt={intl.formatMessage({
                        id: `features.item.title_${item.id}`
                      })}
                    />
                  </Fade>
                </Panel>
              ))}
            </Tab>
          </TabArea>
        </Fade>
      </Container>
    </SectionWrapper>
  );
};

export default injectIntl(FeatureTab);
