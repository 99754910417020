import styled from 'styled-components';

const ThanksSection = styled.section`
  padding: 75px 0;
  @media only screen and (max-width: 1366px) {
    padding: 60px 0;
  }
  @media only screen and (max-width: 667px) {
    padding: 45px 0;
  }

  img {
    margin: 0 auto;
    width: 150px;
    height: auto;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.5);
    }
    @media only screen and (max-width: 667px) {
      margin-top: 5px;
      margin-bottom: 5px;
      width: 120px;
    }
  }
`;

export default ThanksSection;
