import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { plusCircled } from 'react-icons-kit/ionicons/plusCircled';
import { minusCircled } from 'react-icons-kit/ionicons/minusCircled';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import { injectIntl } from 'gatsby-plugin-intl';
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon
} from 'common/src/components/Accordion';
import { SectionHeader } from '../appClassic.style';
import FaqSection from './faq.style';
import { DownloadTag } from '../Guide';

const Faq = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        faq {
          faqs
        }
      }
    }
  `);
  const { faqs } = data.appClassicJson.faq;
  const NUM_FAQ = 5;

  return (
    <FaqSection id='faq'>
      <Container>
        <SectionHeader>
          <Heading content={intl.formatMessage({ id: 'faq.title' })} />
        </SectionHeader>
        <Accordion>
          <div>
            {new Array(NUM_FAQ).fill(1).map((_, index) => {
              const faqIndex = index + 1;
              return (
                <AccordionItem key={`accordion-key--${faqIndex}`}>
                  <div>
                    <AccordionTitle>
                      <Fragment>
                        <Heading
                          as='h3'
                          content={intl.formatMessage({
                            id: `faq.questions.question_${faqIndex}`
                          })}
                        />
                        <IconWrapper className='icon-wrapper'>
                          <OpenIcon>
                            <Icon icon={minusCircled} size={18} />
                          </OpenIcon>
                          <CloseIcon>
                            <Icon icon={plusCircled} size={18} />
                          </CloseIcon>
                        </IconWrapper>
                      </Fragment>
                    </AccordionTitle>
                    <AccordionBody>
                      {index === 4 && <DownloadTag />}
                      {index !== 4 && (
                        <div>
                          {intl
                            .formatMessage({
                              id: `faq.questions.answer_${faqIndex}`
                            })
                            .split('*')
                            .map((bulletPoint, indexBullet) => (
                              <Fragment key={`bullterpoint_${indexBullet}`}>
                                <Text
                                  content={
                                    <span>
                                      {indexBullet !== 0 && <span>∙</span>}
                                      {bulletPoint}
                                    </span>
                                  }
                                />
                              </Fragment>
                            ))}
                        </div>
                      )}
                    </AccordionBody>
                  </div>
                </AccordionItem>
              );
            })}
          </div>
        </Accordion>
      </Container>
    </FaqSection>
  );
};

export default injectIntl(Faq);
