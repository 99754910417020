import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Logo from 'common/src/components/UIElements/Logo';
import SectionWrapper, { ImageSlider, ImageSlide } from './clientBlock.style';
import { SectionHeader } from '../appClassic.style';
import Fade from 'react-reveal/Fade';
import Heading from 'reusecore/src/elements/Heading';
import { injectIntl } from 'gatsby-plugin-intl';
import Container from 'common/src/components/UI/Container';

const ClientBlock = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        clients {
          id
          logo {
            publicURL
          }
          link
          name
        }
      }
    }
  `);

  return (
    <SectionWrapper>
      <Container className='row'>
        <SectionHeader>
          <Fade up>
            <Heading
              as='h5'
              content={intl.formatMessage({ id: 'client.slogan' })}
            />
            {/* <Heading content={intl.formatMessage({ id: 'client.slogan' })} /> */}
          </Fade>
        </SectionHeader>
        <ImageSlider>
          <ImageSlide>
            {data.appClassicJson.clients.map(item => (
              <Logo
                newTab={true}
                key={`slide1__key${item.id}`}
                href={item.link}
                logoSrc={item.logo.publicURL}
                title={item.name}
              />
            ))}
          </ImageSlide>
          <ImageSlide>
            {data.appClassicJson.clients.map(item => (
              <Logo
                newTab={true}
                key={`slide2__key${item.id}`}
                href={item.link}
                logoSrc={item.logo.publicURL}
                title={item.name}
              />
            ))}
          </ImageSlide>
        </ImageSlider>
      </Container>
    </SectionWrapper>
  );
};

export default injectIntl(ClientBlock);
