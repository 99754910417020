import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';

import { SectionHeader, RowResp } from '../appClassic.style';
import GuideSection from './thanks.style';
import Fade from 'react-reveal/Fade';
import Image from 'reusecore/src/elements/Image';
import { injectIntl } from "gatsby-plugin-intl";

const Thanks = ({intl}) => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        thanks {
          sponsors {
            image {
              publicURL
            }
          }
        }
      }
    }
  `);
  const { sponsors } = data.appClassicJson.thanks;

  return (
    <GuideSection id="guide">
      <Container>
        <SectionHeader>
         <Heading as="h5" content={intl.formatMessage({id: 'thanks.title'}).toUpperCase()} />
        </SectionHeader>
        {/* <ImageWrapper border={'borders.3'} colors='primary'  source={image.publicURL}></ImageWrapper> */}
        <Fade up delay={100}>
            <RowResp>
              {sponsors.map( (item, ind) => <Image key={`key_$sponsors_${ind}`} style={{padding: 5}} src={item.image.publicURL} alt="Banner" />)}
            </RowResp>
          </Fade>
      </Container>
    </GuideSection>
  );
};

export default injectIntl(Thanks);
