import React, { Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Text from "reusecore/src/elements/Text";
import Image from "reusecore/src/elements/Image";
import Heading from "reusecore/src/elements/Heading";
import Container from "common/src/components/UI/Container";
import FeatureBlock from "common/src/components/FeatureBlock";
import GlideCarousel from "common/src/components/GlideCarousel";
import GlideSlide from "common/src/components/GlideCarousel/glideSlide";
import SectionWrapper, { CarouseWrapper, TextWrapper } from "./appSlider.style";
import { SectionHeader } from "../appClassic.style";
import Fade from "react-reveal/Fade";
import { injectIntl } from "gatsby-plugin-intl";

const AppSlider = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        appSlider {
          features {
            id
            icon
          }
          carousel {
            id
            image {
              publicURL
            }
          }
        }
      }
    }
  `);
  const { features, carousel } = data.appClassicJson.appSlider;

  const glideOptions = {
    type: "carousel",
    gap: 0,
    autoplay: 5000,
    perView: 1,
    animationDuration: 700
  };

  return (
    <SectionWrapper>
      <SectionHeader>
        <Fade up>
          <Heading
            as="h5"
            content={intl.formatMessage({ id: "appSlider.slogan" })}
          />
        </Fade>
      </SectionHeader>
      <Container className="row">
        <CarouseWrapper>
          <GlideCarousel
            bullets={true}
            controls={false}
            numberOfBullets={3}
            options={glideOptions}
            carouselSelector="appFeatureSlider"
          >
            <Fragment>
              {carousel.map(item => (
                <GlideSlide key={`feature-side--key${item.id}`}>
                  <Image src={item.image.publicURL} alt={"image slider"} />
                </GlideSlide>
              ))}
            </Fragment>
          </GlideCarousel>
        </CarouseWrapper>
        <TextWrapper>
          <Heading content={intl.formatMessage({ id: `appSlider.title` })} />
          <Text content={intl.formatMessage({ id: `appSlider.description` })} />
          <div style={{ justifyContent: "center", alignItems: "center" }}>
            {features.map(item => (
              <FeatureBlock
                wrapperStyle={{ justifyContent: "space-around" }}
                key={`app-feature--key${item.id}`}
                iconPosition="left"
                // icon={<i className={item.icon}></i>}
                title={
                  <Heading
                    as="h3"
                    content={intl.formatMessage({
                      id: `appSlider.feature_title_${item.id}`
                    })}
                  />
                }
                description={
                  <Text
                    content={intl.formatMessage({
                      id: `appSlider.feature_description_${item.id}`
                    })}
                  />
                }
              />
            ))}
          </div>
        </TextWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default injectIntl(AppSlider);
