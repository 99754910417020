import styled from 'styled-components';

const GuideSection = styled.section`
  padding: 75px 0;

  @media only screen and (max-width: 1366px) {
    padding: 60px 0;
  }
  @media only screen and (max-width: 667px) {
    padding: 45px 0;
  }

  .download_guide {
    text-align: center;
    margin-top: 16px;
    text-decoration-line: underline;
  }

  img {
    margin: 0 auto;
  }
`;

export default GuideSection;
