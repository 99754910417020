import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import Container from 'common/src/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './designedAndBuilt.style';
import { injectIntl } from 'gatsby-plugin-intl';
import { RowResp } from '../appClassic.style';

const DesignedAndBuilt = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        designAndBuilt {
          image {
            publicURL
          }
        }
      }
    }
  `);
  const { image } = data.appClassicJson.designAndBuilt;
  return (
    <SectionWrapper>
      <Container>
        <ContentWrapper>
          <RowResp>
            <div style={{ flex: 1, padding: 20 }} className='image'>
              <Image src={image.publicURL} alt='Built Logo' />
            </div>
            <div style={{ flex: 1 }} className='content'>
              <Heading
                content={intl.formatMessage({ id: 'designAndBuilt.title' })}
              />
              <Text
                content={intl.formatMessage({
                  id: 'designAndBuilt.description'
                })}
              />
              <Button
                title={intl.formatMessage({ id: 'designAndBuilt.learnMore' })}
                onClick={() => {
                  if (intl.locale === 'fr') {
                    window.open(
                      'https://actu.epfl.ch/news/de-l-intelligence-artificielle-pour-sauver-les-abe/'
                    );
                  } else {
                    window.open(
                      'https://actu.epfl.ch/news/using-artificial-intelligence-to-save-bees/'
                    );
                  }
                }}
              />
            </div>
          </RowResp>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default injectIntl(DesignedAndBuilt);
