import React from 'react';
import 'antd/dist/antd.css';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/src/theme/appclassic';
import { ResetCSS } from 'common/src/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from '../containers/AppClassic/Navbar';
import Banner from '../containers/AppClassic/Banner';
import QRSection from '../containers/AppClassic/QR';
// import Customer from '../containers/AppClassic/Customer';
import KeyFeatures from '../containers/AppClassic/KeyFeatures';
import AppSlider from '../containers/AppClassic/AppSlider';
// import Features from '../containers/AppClassic/Features';
import DesignedAndBuilt from '../containers/AppClassic/DesignedAndBuilt';
import FeatureTab from '../containers/AppClassic/FeatureTab';
// import PricingPolicy from '../containers/AppClassic/PricingPolicy';
import Testimonial from '../containers/AppClassic/Testimonial';
import Faq from '../containers/AppClassic/Faq';
// import JoinTrail from '../containers/AppClassic/JoinTrail';
import Footer from '../containers/AppClassic/Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper
} from '../containers/AppClassic/appClassic.style';
import { injectIntl } from 'gatsby-plugin-intl';
import SEO from '../components/seo';
import Guide from '../containers/AppClassic/Guide';
import Thanks from '../containers/AppClassic/Thanks';
import ClientBlock from '../containers/AppClassic/ClientBlock';

const IndexPage = ({ intl }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title={intl.formatMessage({ id: 'Seo' })} />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass='sticky-active'>
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <Banner />

            {/* <p> {intl.formatMessage("landing")}</p> */}
            {/* <Customer /> */}
            <KeyFeatures />
            <QRSection />
            <AppSlider />
            {/* <Features /> */}
            <DesignedAndBuilt />
            <FeatureTab />
            {/* <PricingPolicy /> */}
            <Testimonial />
            <ClientBlock />
            <Guide />
            <Faq />
            <Thanks />
            {/* <JoinTrail /> */}
          </ContentWrapper>
          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
};
export default injectIntl(IndexPage);
