import React, { useRef, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { injectIntl } from 'gatsby-plugin-intl';
import { Icon } from 'react-icons-kit';
import { playCircle, pauseCircle } from 'react-icons-kit/fa';
import Text from 'reusecore/src/elements/Text';
import Button from 'reusecore/src/elements/Button';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import BannerWrapper, { BannerContent, ButtonGroup } from './banner.style';
import Scrollspy from 'react-scrollspy';
import { RowResp } from '../appClassic.style';
import Video from '../../../components/Video/video';

const scrollToInside = id => {
  document.location.hash = id;
  window.history.pushState(
    '',
    document.title,
    window.location.pathname + window.location.search
  );
};

const Banner = ({ intl }) => {
  const [videoPlay, setVideoPlay] = useState(false);
  const videoRef = useRef();
  const watchVideo = () => {
    try {
      //vimeo selector catch excetion if video player not loaded
      videoPlay ? videoRef.current.pause() : videoRef.current.play();
      setVideoPlay(!videoPlay);
      //todo add pause
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <BannerWrapper id='home'>
      <Container>
        <RowResp>
          <BannerContent>
            <Fade up delay={100}>
              <Heading
                as='h1'
                content={intl.formatMessage({ id: 'banner.title' })}
              />
            </Fade>
            <Fade up delay={200}>
              <Text
                content={intl.formatMessage({ id: 'banner.description' })}
              />
            </Fade>
            <Fade up delay={300}>
              <Scrollspy
                items={['section-download', 'section-video']}
                currentClassName='is-current'
              >
                <ButtonGroup>
                  <Button
                    className='primary'
                    title={intl.formatMessage({ id: 'banner.download' })}
                    onClick={() => {
                      scrollToInside('section-download');
                    }}
                  />
                  <Button
                    className='text'
                    variant='textButton'
                    icon={<Icon icon={videoPlay ? pauseCircle : playCircle} />}
                    iconPosition='left'
                    title={intl.formatMessage({
                      id: videoPlay ? 'banner.pause' : 'banner.watch'
                    })}
                    onClick={() => {
                      watchVideo();
                    }}
                  />
                </ButtonGroup>
              </Scrollspy>
            </Fade>
          </BannerContent>
          {/* <BannerImage> */}
          <div>
            <Fade up delay={100}>
              <Video
                ref={videoRef}
                locale={intl.locale}
                controls={false}
                onClick={watchVideo}
              />
            </Fade>
          </div>

          {/* </BannerImage> */}
        </RowResp>
      </Container>
    </BannerWrapper>
  );
};

export default injectIntl(Banner);
